import React, { useState } from 'react';

const LayoutContext = React.createContext();

export { LayoutContext };

function LayoutContextContainer(props) {
  const [toPath, setToPath] = useState('');
  return (
    <LayoutContext.Provider value={{
      toPath,
      setToPath,
    }}
    >
      { props.children }
    </LayoutContext.Provider>
  );
}

export default LayoutContextContainer;
