import {
  chineseLanguageLogo,
  malayLanguageLogo,
  languageIcon,
} from '../../images';

export function multipleDummyData(n, data) {
  const latestData = [];
  for (let i = 0; i < n; i++) {
    latestData.push(data);
  }
  return latestData;
}

export function generateHourList() {
  const hourList = [];
  for (let i = 0; i < 12; i++) {
    hourList.push(i);
  }
  return hourList;
}

export function genrateMinList() {
  const minList = [];
  for (let i = 0; i < 59; i++) {
    minList.push(i);
  }
  return minList;
}

export function changeLocale(locale) {
  switch (locale) {
  case 'en':
    return 'English';
  case 'cn':
    return '中文';
  case 'my':
    return 'Malay';
  }
}

export function changeLanguageLogo(locale) {
  switch (locale) {
  case 'en':
    return languageIcon;
  case 'cn':
    return chineseLanguageLogo;
  case 'my':
    return malayLanguageLogo;
  }
}

export function selectorStyle(type, required) {
  const isTimeSelector = type == 'time';
  const inputStyles = {
    menu: (provided, state) => ({
      backgroundColor: 'white',
      borderRadius: 5,
      borderWidth: 0.5,
    }),
    control: (styles) => ({
      ...styles,
      fontSize: 12,
      borderRadius: 4,
      marginBottom: 5,
      height: isTimeSelector ? 20 : 40,
      border: 'none',
      backgroundColor: 'white',
    }),
    option: (styles, {
      data, isDisabled, isFocused, isSelected,
    }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? 'rgb(237, 237, 237)'
          : isFocused
            ? 'rgb(237, 237, 237)'
            : undefined,
      fontSize: 12,
      cursor: 'pointer',
      color: 'black',
      ':active': {
        ...styles[':active'],
        background: '#f2f2f2',
      },
      ':hover': {
        ...styles[':active'],
        background: '#f2f2f2',
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: 'black',
    }),
  };
  return inputStyles;
}

export function amountFormatter(amount) {
  return amount?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}
