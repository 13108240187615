import bannerBtm1 from './dashboard/btmBanner/Row-3-01-Live-Casino.webp';
import bannerBtm2 from './dashboard/btmBanner/Row-3-02-Slots.png';
import bannerBtm3 from './dashboard/btmBanner/Row-3-03-Avatrix.webp';
import bannerBtm4 from './dashboard/btmBanner/Row-3-04-Card-Games.webp';
import bannerBtm5 from './dashboard/btmBanner/Row-3-05-Table-Games.png';
import bannerBtm6 from './dashboard/btmBanner/Row-3-06-esports.png';
import bannerBtm7 from './dashboard/btmBanner/Row-3-07-Referral.png';
import bannerBtm8 from './dashboard/btmBanner/Row-3-08-Rebate.png';
import bannerBtm9 from './dashboard/btmBanner/Row-3-09-VIP.png';

import bannerRow2_1 from './dashboard/btmBanner/row-2-01-VIPCS-250624.webp';
import bannerRow2_2 from './dashboard/btmBanner/row-2-02-9Wicket-250624.webp';
import bannerRow2_3 from './dashboard/btmBanner/row-2-03-EpicWin-250624.webp';
import bannerRow2_4 from './dashboard/btmBanner/row-2-04-ILOVEU-250624.webp';
import bannerRow4_1 from './dashboard/btmBanner/row-4-01-Promo-250624.webp';
import bannerRow4_2 from './dashboard/btmBanner/row-4-02-VIP-250624.webp';
import bannerRow4_3 from './dashboard/btmBanner/row-4-03-Download-250624.webp';
import bannerRow4_4 from './dashboard/btmBanner/row-4-04-Cricket-250624.webp';

import bg from './bg.jpg';
import history from './footer/history_icon.png';
import getBonus from './header/getBonus.png';
import hamburgersvg from './header/Hamburger_1.svg';
import logo from './header/logo.png';
import loading_gif from './loading.gif';
import noData from './noData.png';
import speakerImg from './speaker.png';

import home_banner from './dashboard/home_banner.jpg';
import house from './footer/house_icon.png';
import phone from './footer/phone_icon.png';
import present from './footer/present_icon.png';
import wallet from './footer/wallet_icon.png';
import language from './language/english_language_icon.png';
import languagesvg from './language/Language_1.svg';

import BG_Home_Banner from './dashboard/BG-Home-Banner.webp';
import facebookLogo from './dashboard/facebookLogo.png';
import followour from './dashboard/followour.png';
import followUs from './dashboard/followUs.png';
import intagramLogo from './dashboard/instagramLogo.png';
import latestnews from './dashboard/latestnews.png';
import lineLogo from './dashboard/lineLogo.png';
import telegramLogo from './dashboard/telegramLogo.png';
import twitterLogo from './dashboard/twitterLogo.png';
import youtueLogo from './dashboard/youtubeLogo.png';

import hotGameBG from './dashboard/hotGame/bg.png';
import hotGame1 from './dashboard/hotGame/game1.png';
import hotGame2 from './dashboard/hotGame/game2.png';
import hotGame3 from './dashboard/hotGame/game3.png';
import hotGameText from './dashboard/hotGame/hot-game.png';

import card_icon from './bank/card.png';
import empty_banklist from './bank/empty_banklist.png';
import usdt_icon from './bank/usdt.png';
import profile_change_password_icon from './dashboard/profileModal/change_password_icon.png';
import logo_test_icon from './dashboard/profileModal/logo_test_icon.png';
import profile_my_profile_icon from './dashboard/profileModal/my_profile_icon.png';
import profile_bank_info_icon from './dashboard/profileModal/profile_bank_info_icon.png';
import profile_deposit_icon from './dashboard/profileModal/profile_deposit_icon.png';
import profile_history_icon from './dashboard/profileModal/profile_history_icon.png';
import profile_language_icon from './dashboard/profileModal/profile_language_icon.png';
import profile_logout from './dashboard/profileModal/profile_logout.png';
import profile_withdraw_icon from './dashboard/profileModal/profile_withdraw_icon.png';
import chinese_language_logo from './language/chinese_language_logo.png';
import malay_language_logo from './language/malay_language_logo.png';
import avatar_icon from './profile/avatar_icon.svg';
import logout_icon from './profile/logout_icon.png';
import verification_fail_icon from './profile/verification_fail_icon.png';
import verify_email_icon from './profile/verify_email_icon.png';
import verify_fail_icon_small from './profile/verify_fail_icon_small.png';
import verify_identity_icon from './profile/verify_identity_icon.png';
import verify_in_progress_icon from './profile/verify_in_progress_icon.png';
import verify_pass_icon_small from './profile/verify_pass_icon_small.png';
import verify_phone_icon from './profile/verify_phone_icon.png';
import walletDeposit from './wallet/Deposit.svg';
import duitNow_Icon from './wallet/duitNow_Icon.png';
import walletHistory from './wallet/History.svg';
import top_up_logo from './wallet/top_up_logo.png';
import top_up_usdt_logo from './wallet/top_up_usdt_icon.png';
import walletWithdrawal from './wallet/Withdraw.svg';

import walletDepositSelected from './wallet/Deposit Selected.svg';
import walletHistorySelected from './wallet/History Selected.svg';
import walletWithdrawalSelected from './wallet/Withdraw Selected.svg';

import helpdesk_pic from './helpdesk/helpdesk_pic.png';
import helddesk_phone from './helpdesk/phone.png';

import gameBanner4d from './game/banner/4d.png';
import gameBannerCard from './game/banner/card.png';
import gameBannerCard2 from './game/banner/card2.png';
import gameBannerCasino from './game/banner/casino.png';
import gameBannerCasino2 from './game/banner/casino2.png';
import gameBannerInstantGame from './game/banner/instant-game.png';
import gameBannerInstantGame2 from './game/banner/instant-game2.png';
import gameBannerLive from './game/banner/live.png';
import gameBannerLive2 from './game/banner/live2.png';
import gameBannerSlots from './game/banner/slots.png';
import gameBannerSlots2 from './game/banner/slots2.png';
import gameBannerSport from './game/banner/sport.png';
import gameBannerTable from './game/banner/table.png';
import gameBannerTable2 from './game/banner/table2.png';

import cryptoIconDeposit from './deposit/Cryto.png';
import customerServiceIconDeposit from './deposit/Customer Service TopUp.png';
import depositFail from './deposit/fail.png';
import onlineTransferIconDeposit from './deposit/Online Transfer.png';
import ospayIconDeposit from './deposit/ospay.png';
import rarepidIconDeposit from './deposit/rarepid.png';
import depositSuccessGreen from './deposit/success-green.png';
import depositSuccess from './deposit/success.png';
import vpayIconDeposit from './deposit/Vpay.png';

import contact_selected from './footer/contact_selected.png';
import contact_unselected from './footer/contact_unselected.png';
import history_selected from './footer/history_selected.png';
import history_unselected from './footer/history_unselected.png';
import house_selected from './footer/house_selected.png';
import house_unselected from './footer/house_unselected.png';
import present_selected from './footer/present_selected.png';
import present_unselected from './footer/present_unselected.png';
import profile_selected from './footer/profile_selected.png';
import profile_unselected from './footer/profile_unselected.png';
import wallet_selected from './footer/wallet_selected.png';
import wallet_unselected from './footer/wallet_unselected.png';

// footer license
import footer_boost from './footerLicenses/footer_boost.png';
import footer_cert_1 from './footerLicenses/footer_cert_1.png';
import footer_cert_2 from './footerLicenses/footer_cert_2.png';
import footer_cert_3 from './footerLicenses/footer_cert_3.png';
import footer_cert_4 from './footerLicenses/footer_cert_4.png';
import footer_cert_5 from './footerLicenses/footer_cert_5.png';
import footer_cert_6 from './footerLicenses/footer_cert_6.png';
import footer_cert_7 from './footerLicenses/footer_cert_7.png';
import footer_duitnow from './footerLicenses/footer_duitnow.png';
import footer_fb from './footerLicenses/footer_fb.png';
import footer_fb_hover from './footerLicenses/footer_fb_hover.png';
import footer_game_license from './footerLicenses/footer_game_license.png';
import footer_grab from './footerLicenses/footer_grab.png';
import footer_insta from './footerLicenses/footer_insta.png';
import footer_instagram_hover from './footerLicenses/footer_instagram_hover.png';
import footer_messanger_hover from './footerLicenses/footer_messanger_hover.png';
import footer_messenger from './footerLicenses/footer_messenger.png';
import footer_rg_1 from './footerLicenses/footer_rg_1.png';
import footer_rg_2 from './footerLicenses/footer_rg_2.png';
import footer_rg_3 from './footerLicenses/footer_rg_3.png';
import footer_shopee from './footerLicenses/footer_shopee.png';
import footer_telegram from './footerLicenses/footer_telegram.png';
import footer_telegram_hover from './footerLicenses/footer_telegram_hover.png';
import footer_tng from './footerLicenses/footer_tng.png';
import footer_whatsapp from './footerLicenses/footer_whatsapp.png';
import footer_whatsapp_hover from './footerLicenses/footer_whatsapp_hover.png';
import footer_youtube from './footerLicenses/footer_youtube.png';
import footer_youtube_hover from './footerLicenses/footer_youtube_hover.png';

// footer responsible gaming
import eightteen_plus_logo from './footer/responsible_gaming/18LogoDarkVersion.webp';

// VIP
import vip_bronze from './vip/bronze.png';
import vip_diamond from './vip/diamond.png';
import vip_gold from './vip/gold.png';
import vip_normal from './vip/normal.png';
import vip_platinum from './vip/platinum.png';
import vip_silver from './vip/silver.png';

// Hamburger Menu
import faq_icon from './hamburgerMenu/menu_faq.svg';
import menu_history from './hamburgerMenu/menu_history.svg';
import menu_home from './hamburgerMenu/menu_home.svg';
import menu_logout from './hamburgerMenu/menu_logout.svg';
import menu_profile from './hamburgerMenu/menu_profile.png';
import menu_promotion from './hamburgerMenu/menu_promotion.svg';
import menu_referral from './hamburgerMenu/menu_referral.svg';
import menu_vip from './hamburgerMenu/menu_vip.svg';
import menu_wallet from './hamburgerMenu/menu_wallet.svg';
import top_menu_notification from './hamburgerMenu/notification.png';
import top_menu_profile from './hamburgerMenu/profile.png';

import ambank_logo from './bank/ambank_logo.png';
import bsn_logo from './bank/bsn_logo.png';
import cimb_logo from './bank/cimb_logo.png';
import duitNow_logo from './bank/duitNow_logo.png';
import ewallet_logo from './bank/ewallet_logo.png';
import hl_logo from './bank/hl_logo.png';
import maybank_logo from './bank/maybank_logo.png';
import pb_logo from './bank/pb_logo.png';
import rhb_logo from './bank/rhb_logo.png';
import tng_merchant from './bank/tng.png';
import tng_logo from './bank/tng_logo.png';

import ambank_logo_payment_selected from './bank/Payment Selected/ambank_logo.png';
import bsn_logo_payment_selected from './bank/Payment Selected/bsn_logo.png';
import cimb_logo_payment_selected from './bank/Payment Selected/cimb_logo.png';
import duitNow_logo_payment_selected from './bank/Payment Selected/duitNow_logo.png';
import hl_logo_payment_selected from './bank/Payment Selected/hl_logo.png';
import maybank_logo_payment_selected from './bank/Payment Selected/maybank_logo.png';
import pb_logo_payment_selected from './bank/Payment Selected/pb_logo.png';
import rhb_logo_payment_selected from './bank/Payment Selected/rhb_logo.png';
import tng_logo_payment_selected from './bank/Payment Selected/tng_logo.png';

// Ramadan
import checkinCharacter1 from './events/1.png';
import checkinCharacter2 from './events/2.png';
import checkinCharacter3 from './events/3.png';
import checkinCharacter4 from './events/4.png';
import checkinBg from './events/bg.png';
import claimNow from './events/claimNow.gif';
import afterclaim from './events/coin/afterclaim.png';
import beforeclaim from './events/coin/beforeclaim.gif';
import eventscheckin from './events/coin/checkin.png';
import eventsCoin from './events/coin/coin.png';
import checkinTitle from './events/title.png';

// Wheel Spin
import luckySpin from './events/wheelSpin/2B1_1 3.gif';
import wheelSpinArrow from './events/wheelSpin/wheel_spin_arrow.png';
import wheelSpinButton from './events/wheelSpin/wheel_spin_button.png';
import wheelSpinInner from './events/wheelSpin/wheel_spin_inner.png';
import wheelSpinOuter from './events/wheelSpin/wheel_spin_outer.gif';
import wheelSpinOuterSlow from './events/wheelSpin/wheel_spin_outer_slow.gif';

import cert1 from './footer/cert/cert1.png';
import cert2 from './footer/cert/cert2.png';
import cert3 from './footer/cert/cert3.png';
import cert4 from './footer/cert/cert4.png';
import cert5 from './footer/cert/cert5.png';
import gaming1 from './footer/license/gaming1.webp';
import gaming2 from './footer/license/gaming2.png';
import gaming3 from './footer/license/gaming3.png';
import gaming4 from './footer/license/gaming4.png';
import boost from './footer/payment/boost.png';
import duitnow from './footer/payment/duitnow.png';
import grab from './footer/payment/grab.png';
import shopee from './footer/payment/shopee.png';
import tng from './footer/payment/tng.png';
import UPI from './footer/payment/upi.webp';
import security1 from './footer/security/security1.png';
import security2 from './footer/security/security2.webp';
import security3 from './footer/security/security3.webp';
import security4 from './footer/security/security4.webp';
import security5 from './footer/security/security5.webp';
import fb from './social/fb.png';
import ig from './social/ig.png';
import livechat from './social/livechat.png';
import tg from './social/tg.png';
import wa from './social/wa.png';
import wechat from './social/wechat.png';
import youtube from './social/youtube.png';

import refer_chat_poster from './dashboard/refer_chat_poster.png';
import copyButton from './profile/copy.png';
import global_icon from './profile/global.png';
import history_icon from './profile/history.png';
import inviteIcon from './profile/invite.svg';
import change_password_icon from './profile/lock.png';
import profileRecommend_icon from './profile/profile_recommend.png';
import shareButton from './profile/share.png';
import transaction_icon from './profile/transaction.png';
import withdraw_pw_icon from './profile/withrawalPw.svg';

import airPodImage from './howToSpin/airpod.png';
import bagImage from './howToSpin/bag.png';
import cashInImage from './howToSpin/deposit.png';
import goldImage from './howToSpin/gold.png';
import iphoneImage from './howToSpin/iphone.png';
import appleWatchImage from './howToSpin/iwatch.png';
import spinImage from './howToSpin/spin.png';
import ticketImage from './howToSpin/ticket.png';
import watchImage from './howToSpin/watch.png';

// Game vendor
import onextwogaming from './gameVendor/1x2gaming.png';
import allbetLogo from './gameVendor/allbet.png';
import betsoft from './gameVendor/betsoft.png';
import biggaming from './gameVendor/big gaming.png';
import BnG from './gameVendor/BnG.png';
import evolution from './gameVendor/evolution.png';
import EvoPlay from './gameVendor/EvoPlay.png';
import ezugi from './gameVendor/ezugi.png';
import habanero from './gameVendor/habanero.png';
import hacksaw from './gameVendor/hacksaw.png';
import jdb from './gameVendor/jdb.png';
import jili from './gameVendor/jili.png';
import joker from './gameVendor/joker.png';
import kingmaker from './gameVendor/kingmaker.png';
import live22 from './gameVendor/live22.png';
import m8bet from './gameVendor/m8bet.png';
import microgaming from './gameVendor/microgaming.png';
import mobileProviderLogo from './gameVendor/mobileProviderLogo.gif';
import Netent from './gameVendor/Netent.png';
import nolimitcity from './gameVendor/nolimit-city.png';
import OneTouch from './gameVendor/OneTouch.png';
import pinnacleLogo from './gameVendor/pinnacle.png';
import playnGO from './gameVendor/playnGO.png';
import Playson from './gameVendor/Playson.png';
import playtech from './gameVendor/playtech.png';
import pragmaticplay from './gameVendor/pragmatic play.png';
import quickspin from './gameVendor/quickspin.png';
import redtiger from './gameVendor/red tiger.png';
import relaxgaming from './gameVendor/relaxgaming.png';
import sexybaccarat from './gameVendor/sexy baccarat.png';
import spadegaming from './gameVendor/spadegaming.png';
import Spinomenal from './gameVendor/Spinomenal.png';
import superspade from './gameVendor/superspade.png';
import sv388 from './gameVendor/sv388.png';
import wazdan from './gameVendor/wazdan.png';
import webProviderLogo from './gameVendor/webProviderLogo.gif';
import YGGDRASIL from './gameVendor/YGGDRASIL.png';
import youlian from './gameVendor/youlian.png';

import newDashboardPointBalance from './dashboard/newDashboard/pointBalance.png';
import newDashboardWalletBalance from './dashboard/newDashboard/walletBalance.png';

import newDashboard_lineDesktop from './dashboard/newDashboard/lineDesktop.png';

import loading1 from './dashboard/loading/1.png';
import loading2 from './dashboard/loading/2.png';
import loading3 from './dashboard/loading/3.png';
import loading4 from './dashboard/loading/4.png';
import loading5 from './dashboard/loading/5.png';
import depositPaymentLoading from './dashboard/loading/deposit-payment-loading.gif';

import betLeon77bannerbg from './dashboard/newDashboard/banner/BLeon77-banner-bg.png';
import betLeon77bannerlayer1 from './dashboard/newDashboard/banner/BLeon77-banner-layer1.png';
import betLeon77bannerlayer2 from './dashboard/newDashboard/banner/BLeon77-banner-layer2.png';
import betLeon77bannerlayer3 from './dashboard/newDashboard/banner/BLeon77-banner-layer3.png';
import betLeon77bannerMobileFull from './dashboard/newDashboard/banner/BLeon77-banner-mobile-full.jpg';

import matchbanner1 from './dashboard/newDashboard/banner/Row-2-01-Pro-Kabaddi-League.webp';
import matchbanner2 from './dashboard/newDashboard/banner/Row-2-02-Laliga.webp';
import matchbanner3 from './dashboard/newDashboard/banner/Row-2-03-Bundesliga.webp';
import matchbanner4 from './dashboard/newDashboard/banner/Row-2-04-Australian-Open.webp';

import filter_icon from './game/filter_icon.png';
import gameDefaultImage from './game/Group 5812.png';

import menu_icon_casino from './navMenu/menuIconCasino.png';
import menu_icon_faq from './navMenu/menuIconFAQ.png';
import menu_icon_fishing from './navMenu/menuIconFishing.png';
import menu_icon_history from './navMenu/menuIconHistory.png';
import menu_icon_live_casino from './navMenu/menuIconLiveCasino.png';
import menu_icon_live_stream from './navMenu/menuIconLiveStream.png';
import menu_icon_promotion from './navMenu/menuIconPromotion.png';
import menu_icon_referral from './navMenu/menuIconReferral.png';
import menu_icon_slots from './navMenu/menuIconSlots.png';
import menu_icon_sport from './navMenu/menuIconSport.png';
import menu_icon_terms_conditions from './navMenu/menuIconTermsAndCondition.png';
import menu_icon_vip from './navMenu/menuIconVIP.png';
import menu_icon_ipl from './navMenu/nav_menu_ipl_icon.svg';
import menu_icon_ipl_colored from './navMenu/nav_menu_ipl_icon_colored.png';

import menuIconalllive from './navMenu/slideMenu/menuIcon-all-live.png';
import menuIconfavorities from './navMenu/slideMenu/menuIcon-favorities.png';
import menuIconpromotion from './navMenu/slideMenu/menuIcon-promotion.png';
import menuIcontopparlays from './navMenu/slideMenu/menuIcon-top-parlays.png';

import menuIconCardGames from './navMenu/slideMenu/menuIcon-cardGames.png';
import menuIconDownloadApp from './navMenu/slideMenu/menuIcon-downloadApp.png';
import menuIconfaqleft from './navMenu/slideMenu/menuIcon-faq.png';
import menuIconhistoryleft from './navMenu/slideMenu/menuIcon-history.png';
import menuIconInstantGames from './navMenu/slideMenu/menuIcon-instantGames.png';
import menuIconpromotiongreenleft from './navMenu/slideMenu/menuIcon-promotion-green.png';
import menuIconreferralleft from './navMenu/slideMenu/menuIcon-referral.png';
import menuIconroulette from './navMenu/slideMenu/menuIcon-roulette.png';
import menuIconslots from './navMenu/slideMenu/menuIcon-slots.png';
import menuIconTableGames from './navMenu/slideMenu/menuIcon-tableGames.png';
import menuIcontermleft from './navMenu/slideMenu/menuIcon-term.png';
import menuIconvipleft from './navMenu/slideMenu/menuIcon-vip.png';

import menuchevronLeft from './navMenu/menuchevronLeft.png';

import menucasino from './navMenu/menu-casino.svg';
import menufish from './navMenu/menu-fish.svg';
import menulive from './navMenu/menu-live.svg';
import menulivecasino from './navMenu/menu-livecasino.svg';
import menuslot from './navMenu/menu-slot.svg';
import menuspot from './navMenu/menu-spot.svg';

import navmenuCardGames from './navMenu/menu-card-games.png';
import navmenuInstantGames from './navMenu/menu-instant-games.png';
import navmenuLivecasino from './navMenu/menu-live-casino.png';
import navmenuLiveEvents from './navMenu/menu-live-events.png';
import navmenuSlot from './navMenu/menu-slot.png';
import navmenuTableGames from './navMenu/menu-table-games.png';
import navmenuUpcomingMatches from './navMenu/menu-upcoming-matches.png';
import navmenuVirtualSports from './navMenu/menu-virtual-sports.png';

import sideMenuBack from './navMenu/slideMenu/side-menu-back.png';
import sideMenuLanguageDown from './navMenu/slideMenu/side-menu-language-down.png';

import navbarCasino from './navbarBottom/casino.png';
import navbarCouponRegular from './navbarBottom/coupon-Regular.png';
import navbarHistory from './navbarBottom/history.png';
import navbarMain from './navbarBottom/main.png';
import navbarMenu from './navbarBottom/menu.png';
import navbarProfile from './navbarBottom/profile.png';
import navbarReferral from './navbarBottom/referral.png';
import navbarSports from './navbarBottom/sports.png';

import main_page_hot_game_side_banner from './mobileAllGame/mainPageHotGameSideBanner.png';
import mobile_all_game_banner_1 from './mobileAllGame/mobileAllGameBanner1.png';
import mobile_all_game_banner_10 from './mobileAllGame/mobileAllGameBanner10.png';
import mobile_all_game_banner_2 from './mobileAllGame/mobileAllGameBanner2.png';
import mobile_all_game_banner_3 from './mobileAllGame/mobileAllGameBanner3.png';
import mobile_all_game_banner_4 from './mobileAllGame/mobileAllGameBanner4.png';
import mobile_all_game_banner_5 from './mobileAllGame/mobileAllGameBanner5.png';
import mobile_all_game_banner_6 from './mobileAllGame/mobileAllGameBanner6.png';
import mobile_all_game_banner_7 from './mobileAllGame/mobileAllGameBanner7.png';
import mobile_all_game_banner_8 from './mobileAllGame/mobileAllGameBanner8.png';
import mobile_all_game_banner_9 from './mobileAllGame/mobileAllGameBanner9.png';

import sportBell from './dashboard/newDashboard/bell.svg';
import sportChart from './dashboard/newDashboard/chart.svg';
import sportTitle from './dashboard/newDashboard/sport-title.png';
import sportType from './dashboard/newDashboard/sport-type.png';
import sportStar from './dashboard/newDashboard/star.svg';
import sportTeamA from './dashboard/newDashboard/teamA.png';
import sportTeamB from './dashboard/newDashboard/teamB.png';
import welcometo from './dashboard/newDashboard/welcomeTo.webp';

import downloadApk from './dashboard/newDashboard/downloadAPK_v2.webp';

import badmintonIcon from './dashboard/newDashboard/sportIcons/Badminton_D.png';
import baseballIcon from './dashboard/newDashboard/sportIcons/Baseball_D.png';
import basketballIcon from './dashboard/newDashboard/sportIcons/Basketball_D.png';
import boxingIcon from './dashboard/newDashboard/sportIcons/Boxing_D.png';
import cricketIcon from './dashboard/newDashboard/sportIcons/Cricket_D.png';
import curlingIcon from './dashboard/newDashboard/sportIcons/Curling_D.png';
import esportIcon from './dashboard/newDashboard/sportIcons/Esport_D.png';
import footBallIcon from './dashboard/newDashboard/sportIcons/Football_D.png';
import golfIcon from './dashboard/newDashboard/sportIcons/Golf_D.png';
import handballIcon from './dashboard/newDashboard/sportIcons/Hanball_D.png';
import hockeyIcon from './dashboard/newDashboard/sportIcons/Hockey_D.png';
import kabaddiIcon from './dashboard/newDashboard/sportIcons/kabaddi_D.png';
import mmaIcon from './dashboard/newDashboard/sportIcons/MMA_D.png';
import politicsIcon from './dashboard/newDashboard/sportIcons/Politic_D.png';
import rubyUnionIcon from './dashboard/newDashboard/sportIcons/RubgyUnion_D.png';
import rugbyLeagueIcon from './dashboard/newDashboard/sportIcons/RudgyLeague_D.png';
import snookerIcon from './dashboard/newDashboard/sportIcons/Snooker_D.png';
import soccerIcon from './dashboard/newDashboard/sportIcons/Soceer_D.png';
import tennisIcon from './dashboard/newDashboard/sportIcons/Tennis_D.png';
import volleyballIcon from './dashboard/newDashboard/sportIcons/Volleyball_D.png';
import waterPoloIcon from './dashboard/newDashboard/sportIcons/WaterPolo_D.png';

import innerBackground_02 from './game/InnerBackground-02.png';

import footericon1 from './footer/introduction/footer-icon-1.png';
import footericon2 from './footer/introduction/footer-icon-2.png';
import footericon3 from './footer/introduction/footer-icon-3.png';
import footericon4 from './footer/introduction/footer-icon-4.png';
import footericon5 from './footer/introduction/footer-icon-5.png';

import custom_live_chat_icon from './custom_live_chat_icon.png';
import helpCenterMobile from './help-center-mobile.webp';
import helpCenter from './help-center.webp';
import helpCenterLine from './helpcenter-line.png';

import sidebar_faq_icon from './sidebar/sidebar_faq_iconNEW.png';
import sidebar_livechat_icon from './sidebar/sidebar_livechat_iconNEW.png';

import sidebar_email_icon_event from './sidebar/sidebar_email_icon_event.png';
import sidebar_facebook_icon_event from './sidebar/sidebar_facebook_icon_event.png';
import sidebar_faq_icon_event from './sidebar/sidebar_faq_icon_event.png';
import sidebar_livechat_icon_event from './sidebar/sidebar_livechat_icon_event.png';
import sidebar_messenger_icon_event from './sidebar/sidebar_messenger_icon_event.png';

import sidebar_email from './sidebar/sidebar_email_icon.webp';
import sidebar_facebook from './sidebar/sidebar_facebook_icon.webp';
import sidebar_help from './sidebar/sidebar_help_icon.webp';
import sidebar_livechat from './sidebar/sidebar_livechat_icon.webp';
import sidebar_messenger from './sidebar/sidebar_messenger_icon.webp';
import sidebar_telegram from './sidebar/sidebar_telegram_icon.webp';

import sidebar_email_hover from './sidebar/sidebar_email_icon_hover.webp';
import sidebar_facebook_hover from './sidebar/sidebar_facebook_icon_hover.webp';
import sidebar_help_hover from './sidebar/sidebar_help_icon_hover.webp';
import sidebar_livechat_hover from './sidebar/sidebar_livechat_icon_hover.webp';
import sidebar_messenger_hover from './sidebar/sidebar_messenger_icon_hover.webp';
import sidebar_telegram_hover from './sidebar/sidebar_telegram_icon_hover.webp';

import ip_restricted from './ip_restriction.png';
import ip_restricted_betleon_logo from './ipRestriction/ip_restriction_betleon_logo.png';
import ip_restriction_icon from './ipRestriction/ip_restriction_icon.png';

import chevronDown from './dashboard/newDashboard/chevron-down.png';
import chevronUp from './dashboard/newDashboard/chevron-up.png';
import chevronLeft from './dashboard/newDashboard/chevronLeft.png';
import chevronRight from './dashboard/newDashboard/chevronRight.png';
import chevronRightBold from './dashboard/newDashboard/chevronRightBold.png';
import signupBannerLogo from './signup/banner-logo.png';
import signupBanner from './signup/banner.png';
import signupBannerNoLogo from './signup/signupBanner-no-logo.png';

import aboutUs from './aboutUs.png';

import transferIcon from './icon-info.png';

// sports
import teamAway from './sports/team_away.png';
import teamHome from './sports/team_home.png';

// flag
import ukFlag from '../public/flags/gb.svg';

// new mobile banners
import depositReminderField from './deposit/deposit_reminder_field.png';
import gameBannerCardGameMobile from './game/banner/game_banner_card_game_mobile.png';
import gameBannerCardGameMobile2 from './game/banner/game_banner_card_game_mobile_2.png';
import gameBannerCasinoMobile from './game/banner/game_banner_casino_mobile.png';
import gameBannerCasinoMobile2 from './game/banner/game_banner_casino_mobile_2.png';
import gameBannerInstantGameMobile from './game/banner/game_banner_instant_game_mobile.png';
import gameBannerInstantGameMobile2 from './game/banner/game_banner_instant_game_mobile_2.png';
import gameBannerLiveMobile from './game/banner/game_banner_live_mobile.png';
import gameBannerLiveMobile2 from './game/banner/game_banner_live_mobile_2.png';
import gameBannerSlots3 from './game/banner/game_banner_slots_3.png';
import gameBannerSlotsMobile3 from './game/banner/game_banner_slots_3_mobile.png';
import gameBannerSlots4 from './game/banner/game_banner_slots_4.png';
import gameBannerSlotsMobile4 from './game/banner/game_banner_slots_4_mobile.png';
import gameBannerSlotsMobile from './game/banner/game_banner_slots_mobile.png';
import gameBannerSlotsMobile2 from './game/banner/game_banner_slots_mobile_2.png';
import gameBannerTableGameMobile from './game/banner/game_banner_table_game_mobile.png';
import gameBannerTableGameMobile2 from './game/banner/game_banner_table_game_mobile_2.png';

import iplFloatIcon from './dashboard/ipl_float_icon.png';

// Aviator Assets

export { default as AviatorGif } from './campaigns/aviator/aviator-float-icon.gif';
export { default as AviatorDesktop } from './campaigns/aviator/desktop_view.webp';
export { default as AviatorMobile } from './campaigns/aviator/mobile_view.webp';
export { default as FirstPlace } from './campaigns/prizes/firstPlace.webp';
export { default as SecondPlace } from './campaigns/prizes/secondPlace.webp';
export { default as ThirdPlace } from './campaigns/prizes/thirdPlace.webp';

export { default as CampaignsBanner } from './campaigns/banner.png';
export { default as Banner7xwin } from './campaigns/banner7xwin.png';
export { default as Banner7xwin_Mobile_NEW } from './campaigns/banner7xwin_mobile_new.webp';
export { default as Banner7xwin_NEW } from './campaigns/banner7xwin_new.webp';
export { default as CampaignsMobileBanner } from './campaigns/mobilebanner.png';
export { default as Mobilebanner7xwin } from './campaigns/mobilebanner7xwin.png';
export { default as CampaignsPrize1 } from './campaigns/prizes/1.png';
export { default as CampaignsPrize2 } from './campaigns/prizes/2.png';
export { default as CampaignsPrize3 } from './campaigns/prizes/3.png';
export { default as CampaignsPrize4 } from './campaigns/prizes/4.png';
export { default as CampaignsPrizeBg1 } from './campaigns/prizes/bg1.png';
export { default as CampaignsPrizeBg2 } from './campaigns/prizes/bg2.png';
export { default as CampaignsPrizeBg3 } from './campaigns/prizes/bg3.png';
export { default as CampaignsPrizeBg4 } from './campaigns/prizes/bg4.png';
export { default as CampaignsRank1 } from './campaigns/rank1.png';
export { default as CampaignsRank2 } from './campaigns/rank2.png';
export { default as CampaignsRank3 } from './campaigns/rank3.png';

export const BannerBtm1 = bannerBtm1;
export const BannerBtm2 = bannerBtm2;
export const BannerBtm3 = bannerBtm3;
export const BannerBtm4 = bannerBtm4;
export const BannerBtm5 = bannerBtm5;
export const BannerBtm6 = bannerBtm6;
export const BannerBtm7 = bannerBtm7;
export const BannerBtm8 = bannerBtm8;
export const BannerBtm9 = bannerBtm9;

export const BannerRow21 = bannerRow2_1;
export const BannerRow22 = bannerRow2_2;
export const BannerRow23 = bannerRow2_3;
export const BannerRow24 = bannerRow2_4;
export const BannerRow41 = bannerRow4_1;
export const BannerRow42 = bannerRow4_2;
export const BannerRow43 = bannerRow4_3;
export const BannerRow44 = bannerRow4_4;

export const ukFlagsvg = ukFlag;
export const transfIcon = transferIcon;

export const CustomLiveChatIcon = custom_live_chat_icon;
export const HelpCenter = helpCenter;
export const HelpCenterMobile = helpCenterMobile;
export const HelpCenterLine = helpCenterLine;

export const loadingGif = loading_gif;
export const GetBonus = getBonus;

export const GameProvider_youlian = youlian;
export const GameProvider_onextwogaming = onextwogaming;
export const GameProvider_betsoft = betsoft;
export const GameProvider_biggaming = biggaming;

export const GameProvider_BnG = BnG;
export const GameProvider_evolution = evolution;
export const GameProvider_EvoPlay = EvoPlay;
export const GameProvider_ezugi = ezugi;
export const GameProvider_habanero = habanero;
export const GameProvider_hacksaw = hacksaw;
export const GameProvider_jdb = jdb;
export const GameProvider_jili = jili;
export const GameProvider_joker = joker;
export const GameProvider_kingmaker = kingmaker;

export const GameProvider_live22 = live22;
export const GameProvider_m8bet = m8bet;
export const GameProvider_microgaming = microgaming;
export const GameProvider_Netent = Netent;
export const GameProvider_nolimitcity = nolimitcity;
export const GameProvider_OneTouch = OneTouch;
export const GameProvider_playnGO = playnGO;
export const GameProvider_Playson = Playson;
export const GameProvider_playtech = playtech;
export const GameProvider_pragmaticplay = pragmaticplay;

export const GameProvider_quickspin = quickspin;
export const GameProvider_redtiger = redtiger;
export const GameProvider_relaxgaming = relaxgaming;
export const GameProvider_sexybaccarat = sexybaccarat;
export const GameProvider_spadegaming = spadegaming;
export const GameProvider_Spinomenal = Spinomenal;
export const GameProvider_superspade = superspade;
export const GameProvider_sv388 = sv388;
export const GameProvider_wazdan = wazdan;
export const GameProvider_YGGDRASIL = YGGDRASIL;
export const GameProvider_Pinnacle = pinnacleLogo;
export const GameProvider_Allbet = allbetLogo;

export const cimb = cimb_logo;
export const hlb = hl_logo;
export const mbb = maybank_logo;
export const pbe = pb_logo;
export const rhb = rhb_logo;
export const bsn = bsn_logo;
export const ambank = ambank_logo;
export const ewallet = ewallet_logo;

export const PaymentSelectedambank_logo = ambank_logo_payment_selected;
export const PaymentSelectedbsn_logo = bsn_logo_payment_selected;
export const PaymentSelectedcimb_logo = cimb_logo_payment_selected;
export const PaymentSelectedduitNow_logo = duitNow_logo_payment_selected;
export const PaymentSelectedhl_logo = hl_logo_payment_selected;
export const PaymentSelectedmaybank_logo = maybank_logo_payment_selected;
export const PaymentSelectedpb_logo = pb_logo_payment_selected;
export const PaymentSelectedtng_logo = tng_logo_payment_selected;
export const PaymentSelectedrhb_logo = rhb_logo_payment_selected;

export const bgHomeBanner = BG_Home_Banner;
export const background = bg;
export const helpDeskPhoneIcon = helddesk_phone;
export const historyIcon = history;
export const noDataIcon = noData;
export const logoIcon = logo;
export const Hamburgersvg = hamburgersvg;
export const houseIcon = house;
export const profileDepositIcon = profile_deposit_icon;
export const profileWithdrawIcon = profile_withdraw_icon;
export const profileHistoryIcon = profile_history_icon;
export const logoTestIcon = logo_test_icon;
export const profileMyProfileIcon = profile_my_profile_icon;
export const profileChangePasswordIcon = profile_change_password_icon;
export const profileIogoutIcon = profile_logout;
export const logoutIcon = logout_icon;
export const profileBankInfoIcon = profile_bank_info_icon;
export const profileLanguageIcon = profile_language_icon;
export const verifyInProgressIcon = verify_in_progress_icon;
export const verificationFailIcon = verification_fail_icon;
export const verifyEmailIcon = verify_email_icon;
export const verifyPhoneIcon = verify_phone_icon;
export const verifyIdentityIcon = verify_identity_icon;
export const verifyFailIconSmall = verify_fail_icon_small;
export const verifyPassIconSmall = verify_pass_icon_small;

export const phoneIcon = phone;
export const walletIcon = wallet;
export const presentIcon = present;
export const Languagesvg = languagesvg;
export const languageIcon = language;
export const homeBannerPic = home_banner;
export const chineseLanguageLogo = chinese_language_logo;
export const malayLanguageLogo = malay_language_logo;
export const avatarIcon = avatar_icon;
export const bankCardIcon = card_icon;
export const usdtIcon = usdt_icon;
export const emptyBankListIcon = empty_banklist;
export const topUpUsdtLogo = top_up_usdt_logo;
export const topUpLogo = top_up_logo;
export const duitNowIcon = duitNow_Icon;
export const speakerIcon = speakerImg;

export const walletDepositIcon = walletDeposit;
export const walletWithdrawalIcon = walletWithdrawal;
export const walletHistoryIcon = walletHistory;

export const walletDepositSelectedIcon = walletDepositSelected;
export const walletWithdrawalSelectedIcon = walletWithdrawalSelected;
export const walletHistorySelectedIcon = walletHistorySelected;

export const helpdeskPic = helpdesk_pic;
export const duitNowLogo = duitNow_logo;
export const tngMerchant = tng_merchant;
export const tngLogo = tng_logo;

export const GameBanner4d = gameBanner4d;
export const GameBannerCard = gameBannerCard;
export const GameBannerCard2 = gameBannerCard2;
export const GameBannerCasino = gameBannerCasino;
export const GameBannerCasino2 = gameBannerCasino2;
export const GameBannerTable = gameBannerTable;
export const GameBannerTable2 = gameBannerTable2;
export const GameBannerInstantGame = gameBannerInstantGame;
export const GameBannerInstantGame2 = gameBannerInstantGame2;
export const GameBannerLive = gameBannerLive;
export const GameBannerLive2 = gameBannerLive2;
export const GameBannerSport = gameBannerSport;
export const GameBannerSlots = gameBannerSlots;
export const GameBannerSlots2 = gameBannerSlots2;

export const CryptoIconDeposit = cryptoIconDeposit;
export const CustomerServiceIconDeposit = customerServiceIconDeposit;
export const OnlineTransferIconDeposit = onlineTransferIconDeposit;
export const VpayIconDeposit = vpayIconDeposit;
export const RarepidIconDeposit = rarepidIconDeposit;
export const OspayIconDeposit = ospayIconDeposit;
export const DepositSuccess = depositSuccess;
export const DepositSuccessGreen = depositSuccessGreen;
export const DepositFail = depositFail;

export const contactSelected = contact_selected;
export const contactUnselected = contact_unselected;
export const historySelected = history_selected;
export const historyUnselected = history_unselected;
export const houseSelected = house_selected;
export const houseUnselected = house_unselected;
export const presentSelected = present_selected;
export const presentUnselected = present_unselected;
export const walletSelected = wallet_selected;
export const walletUnselected = wallet_unselected;
export const profileSelected = profile_selected;
export const profileUnselected = profile_unselected;

// footer licenses
export const footerBoost = footer_boost;
export const footerCert1 = footer_cert_1;
export const footerCert2 = footer_cert_2;
export const footerCert3 = footer_cert_3;
export const footerCert4 = footer_cert_4;
export const footerCert5 = footer_cert_5;
export const footerCert6 = footer_cert_6;
export const footerCert7 = footer_cert_7;
export const footerDuitnow = footer_duitnow;
export const footerFb = footer_fb;
export const footerGameLicense = footer_game_license;
export const footerGrab = footer_grab;
export const footerInsta = footer_insta;
export const footerRg1 = footer_rg_1;
export const footerRg2 = footer_rg_2;
export const footerRg3 = footer_rg_3;
export const footerShopee = footer_shopee;
export const footerTng = footer_tng;
export const footerYoutube = footer_youtube;
export const footerMessenger = footer_messenger;
export const footerWhatsapp = footer_whatsapp;
export const footerTelegram = footer_telegram;
export const footerFbHover = footer_fb_hover;
export const footerWhatsappHover = footer_whatsapp_hover;
export const footerInstaHover = footer_instagram_hover;
export const footerMessangerHover = footer_messanger_hover;
export const footerTelegramHover = footer_telegram_hover;
export const footerYoutubeHover = footer_youtube_hover;

// footer responsible gaming
export const eighteenPlusDarkLogo = eightteen_plus_logo;

// vip
export const VipNormal = vip_normal;
export const VipBronze = vip_bronze;
export const VipSilver = vip_silver;
export const VipGold = vip_gold;
export const VipPlatinum = vip_platinum;
export const VipDiamond = vip_diamond;

export const LatestNews = latestnews;
export const FollowOur = followour;
export const FacebookLogo = facebookLogo;
export const IntagramLogo = intagramLogo;
export const TwitterLogo = twitterLogo;
export const YoutueLogo = youtueLogo;
export const LineLogo = lineLogo;
export const TelegramLogo = telegramLogo;
export const FollowUs = followUs;

export const HotGameText = hotGameText;
export const HotGameBG = hotGameBG;
export const HotGame1 = hotGame1;
export const HotGame2 = hotGame2;
export const HotGame3 = hotGame3;

// Hamburger menu
export const menuHistoryIcon = menu_history;
export const menuHomeIcon = menu_home;
// export const menuLanguageIcon = menu_language;
export const menuLogoutIcon = menu_logout;
export const menuProfileIcon = menu_profile;
export const menuPromotionIcon = menu_promotion;
export const menuWalletIcon = menu_wallet;
export const faqIcon = faq_icon;
export const menuReferral = menu_referral;
export const menuVip = menu_vip;
export const topmenuprofile = top_menu_profile;
export const topmenunotificationIcon = top_menu_notification;

export const CheckinCharacter1 = checkinCharacter1;
export const CheckinCharacter2 = checkinCharacter2;
export const CheckinCharacter3 = checkinCharacter3;
export const CheckinCharacter4 = checkinCharacter4;
export const CheckinTitle = checkinTitle;
export const CheckinBg = checkinBg;

export const Afterclaim = afterclaim;
export const Beforeclaim = beforeclaim;
export const EventscheckinCoin = eventscheckin;
export const EventsCoin = eventsCoin;
export const ClaimNow = claimNow;

export const LuckySpin = luckySpin;
export const WheelSpinOuter = wheelSpinOuter;
export const WheelSpinOuterSlow = wheelSpinOuterSlow;
export const WheelSpinInner = wheelSpinInner;
export const WheelSpinArrow = wheelSpinArrow;
export const WheelSpinButton = wheelSpinButton;

export const gaming1Icon = gaming1;
export const gaming2Icon = gaming2;
export const gaming3Icon = gaming3;
export const gaming4Icon = gaming4;
export const cert1Icon = cert1;
export const cert2Icon = cert2;
export const cert3Icon = cert3;
export const cert4Icon = cert4;
export const cert5Icon = cert5;
export const security1Icon = security1;
export const security2Icon = security2;
export const security3Icon = security3;
export const security4Icon = security4;
export const security5Icon = security5;
export const tngIcon = tng;
export const boostIcon = boost;
export const duitnowIcon = duitnow;
export const grabIcon = grab;
export const shopeeIcon = shopee;
export const UPIIcon = UPI;
export const fbIcon = fb;
export const igIcon = ig;
export const youtubeIcon = youtube;
export const waIcon = wa;
export const livechatIcon = livechat;
export const tgIcon = tg;
export const wechatIcon = wechat;

export const ProfileRecommendIcon = profileRecommend_icon;
export const HistoryIcon = history_icon;
export const TransactionIcon = transaction_icon;
export const LockIcon = change_password_icon;
export const GlobalIcon = global_icon;
export const WithdrawPw = withdraw_pw_icon;
export const InviteIcon = inviteIcon;
export const ShareIcon = shareButton;
export const CopyButton = copyButton;
export const referChatPoster = refer_chat_poster;

export const depositIcon = cashInImage;
export const spinIcon = spinImage;
export const ticketIcon = ticketImage;
export const airpod = airPodImage;
export const bag = bagImage;
export const appleWatch = appleWatchImage;
export const gold = goldImage;
export const watch = watchImage;
export const iphone = iphoneImage;

export const NewDashboardWalletBalance = newDashboardWalletBalance;
export const NewDashboardPointBalance = newDashboardPointBalance;

export const newDashboardLineDesktop = newDashboard_lineDesktop;

export const Loading1 = loading1;
export const Loading2 = loading2;
export const Loading3 = loading3;
export const Loading4 = loading4;
export const Loading5 = loading5;
export const DepositPaymentLoading = depositPaymentLoading;

export const BLeon77bannerbg = betLeon77bannerbg;
export const BLeon77bannerlayer1 = betLeon77bannerlayer1;
export const BLeon77bannerlayer2 = betLeon77bannerlayer2;
export const BLeon77bannerlayer3 = betLeon77bannerlayer3;
export const BLeon77bannerMobileFull = betLeon77bannerMobileFull;

export const Matchbanner1 = matchbanner1;
export const Matchbanner2 = matchbanner2;
export const Matchbanner3 = matchbanner3;
export const Matchbanner4 = matchbanner4;

export const filterIcon = filter_icon;
export const gameDefaultImagepng = gameDefaultImage;
export const mobileProviderLogoGif = mobileProviderLogo;
export const webProviderLogoGif = webProviderLogo;

export const menuIconFAQ = menu_icon_faq;
export const menuIconHistory = menu_icon_history;
export const menuIconLiveStream = menu_icon_live_stream;
export const menuIconPromotion = menu_icon_promotion;
export const menuIconReferral = menu_icon_referral;
export const menuIconVIP = menu_icon_vip;
export const menuIconCasino = menu_icon_casino;
export const menuIconFishing = menu_icon_fishing;
export const menuIconLiveCasino = menu_icon_live_casino;
export const menuIconSlots = menu_icon_slots;
export const menuIconSport = menu_icon_sport;
export const menuIconTermsAndConditon = menu_icon_terms_conditions;
export const MenuIconpromotion = menuIconpromotion;
export const MenuIconalllive = menuIconalllive;
export const MenuIconfavorities = menuIconfavorities;
export const MenuIcontopparlays = menuIcontopparlays;
export const MenuChevronLeft = menuchevronLeft;
export const MenuIconhistoryleft = menuIconhistoryleft;
export const MenuIconpromotiongreenleft = menuIconpromotiongreenleft;
export const MenuIconreferralleft = menuIconreferralleft;
export const MenuIcontermleft = menuIcontermleft;
export const MenuIconvipleft = menuIconvipleft;
export const MenuIconfaqleft = menuIconfaqleft;
export const MenuIconIPL = menu_icon_ipl;
export const MenuIconIPLColored = menu_icon_ipl_colored;
export const menuIconRoulette = menuIconroulette;
export const menuSlots = menuIconslots;
export const menuTableGames = menuIconTableGames;
export const menuCardGames = menuIconCardGames;
export const menuInstantGames = menuIconInstantGames;
export const menuDownloadApp = menuIconDownloadApp;

export const ChevronLeft = chevronLeft;
export const ChevronRight = chevronRight;
export const ChevronDown = chevronDown;
export const ChevronRightBold = chevronRightBold;
export const ChevronUp = chevronUp;

export const Menufish = menufish;
export const Menulivecasino = menulivecasino;
export const Menulive = menulive;
export const Menuspot = menuspot;
export const Menuslot = menuslot;
export const Menucasino = menucasino;

export const NavmenuCardGames = navmenuCardGames;
export const NavmenuInstantGames = navmenuInstantGames;
export const NavmenuLiveEvents = navmenuLiveEvents;
export const NavmenuSlot = navmenuSlot;
export const NavmenuLivecasino = navmenuLivecasino;
export const NavmenuTableGames = navmenuTableGames;
export const NavmenuUpcomingMatches = navmenuUpcomingMatches;
export const NavmenuVirtualSports = navmenuVirtualSports;

export const SideMenuBack = sideMenuBack;
export const SideMenuLanguageDown = sideMenuLanguageDown;

export const NavbarCasino = navbarCasino;
export const NavbarCouponRegular = navbarCouponRegular;
export const NavbarMain = navbarMain;
export const NavbarMenu = navbarMenu;
export const NavbarProfile = navbarProfile;
export const NavbarSports = navbarSports;
export const NavbarHistory = navbarHistory;
export const NavbarReferral = navbarReferral;

export const MobileAllGameBanner1 = mobile_all_game_banner_1;
export const MobileAllGameBanner2 = mobile_all_game_banner_2;
export const MobileAllGameBanner3 = mobile_all_game_banner_3;
export const MobileAllGameBanner4 = mobile_all_game_banner_4;
export const MobileAllGameBanner5 = mobile_all_game_banner_5;
export const MobileAllGameBanner6 = mobile_all_game_banner_6;
export const MobileAllGameBanner7 = mobile_all_game_banner_7;
export const MobileAllGameBanner8 = mobile_all_game_banner_8;
export const MobileAllGameBanner9 = mobile_all_game_banner_9;
export const MobileAllGameBanner10 = mobile_all_game_banner_10;
export const MainPageHotGameSideBanner = main_page_hot_game_side_banner;

export const SportType = sportType;
export const SportTitle = sportTitle;
export const Welcometo = welcometo;
export const SportTeamA = sportTeamA;
export const SportTeamB = sportTeamB;
export const SportBell = sportBell;
export const SportChart = sportChart;
export const SportStar = sportStar;

export const DownloadApk = downloadApk;

export const InnerBackground02 = innerBackground_02;

export const Footericon1 = footericon1;
export const Footericon2 = footericon2;
export const Footericon3 = footericon3;
export const Footericon4 = footericon4;
export const Footericon5 = footericon5;

export const SnookerIcon = snookerIcon;
export const BasketballIcon = basketballIcon;
export const CricketIcon = cricketIcon;
export const BaseballIcon = baseballIcon;
export const CurlingIcon = curlingIcon;
export const EsportIcon = esportIcon;
export const HandballIcon = handballIcon;
export const SoccerIcon = soccerIcon;
export const PoliticsIcon = politicsIcon;
export const VolleyballIcon = volleyballIcon;
export const TennisIcon = tennisIcon;
export const BadmintonIcon = badmintonIcon;
export const MmaIcon = mmaIcon;
export const RugbyLeagueIcon = rugbyLeagueIcon;
export const RubyUnionIcon = rubyUnionIcon;
export const GolfIcon = golfIcon;
export const WaterPoloIcon = waterPoloIcon;
export const KabaddiIcon = kabaddiIcon;
export const FootBallIcon = footBallIcon;
export const BoxingIcon = boxingIcon;
export const HockeyIcon = hockeyIcon;

export const sidebarLivechatIcon = sidebar_livechat_icon;
export const sidebarFaqIcon = sidebar_faq_icon;
export const sidebarLivechatIconEvent = sidebar_livechat_icon_event;
export const sidebarEmailIconEvent = sidebar_email_icon_event;
export const sidebarFacebookIconEvent = sidebar_facebook_icon_event;
export const sidebarMessengerIconEvent = sidebar_messenger_icon_event;
export const sidebarFaqIconEvent = sidebar_faq_icon_event;

export const sidebarLiveChatIcon = sidebar_livechat;
export const sidebarEmailIcon = sidebar_email;
export const sidebarMessengerIcon = sidebar_messenger;
export const sidebarFacebookIcon = sidebar_facebook;
export const sidebarTelegramIcon = sidebar_telegram;
export const sidebarHelpIcon = sidebar_help;

export const sidebarLiveChatIconHover = sidebar_livechat_hover;
export const sidebarEmailIconHover = sidebar_email_hover;
export const sidebarMessengerIconHover = sidebar_messenger_hover;
export const sidebarFacebookIconHover = sidebar_facebook_hover;
export const sidebarTelegramIconHover = sidebar_telegram_hover;
export const sidebarHelpIconHover = sidebar_help_hover;

export const ipRestricted = ip_restricted;
export const ipRestrctedLogo = ip_restricted_betleon_logo;
export const ipRestrictedIcon = ip_restriction_icon;

export const SignupBanner = signupBanner;
export const SignupBannerLogo = signupBannerLogo;
export const SignupBannerNoLogo = signupBannerNoLogo;

export const AboutUs = aboutUs;

export const TeamAway = teamAway;
export const TeamHome = teamHome;

export const GameBannerCardGameMobile = gameBannerCardGameMobile;
export const GameBannerCardGameMobile2 = gameBannerCardGameMobile2;
export const GameBannerCasinoMobile = gameBannerCasinoMobile;
export const GameBannerCasinoMobile2 = gameBannerCasinoMobile2;
export const GameBannerInstantGameMobile = gameBannerInstantGameMobile;
export const GameBannerInstantGameMobile2 = gameBannerInstantGameMobile2;
export const GameBannerLiveMobile = gameBannerLiveMobile;
export const GameBannerLiveMobile2 = gameBannerLiveMobile2;
export const GameBannerSlotsMobile = gameBannerSlotsMobile;
export const GameBannerSlotsMobile2 = gameBannerSlotsMobile2;
export const GameBannerTableGameMobile = gameBannerTableGameMobile;
export const GameBannerTableGameMobile2 = gameBannerTableGameMobile2;
export const DepositReminderField = depositReminderField;
export const GameBannerSlots3 = gameBannerSlots3;
export const GameBannerSlots4 = gameBannerSlots4;
export const GameBannerSlotsMobile3 = gameBannerSlotsMobile3;
export const GameBannerSlotsMobile4 = gameBannerSlotsMobile4;

export const IPLFloatIcon = iplFloatIcon;
